@import url(https://fonts.googleapis.com/css?family=Merriweather:400,300,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
.blogsBody {
    background: #fbfbfb;
    font-family: "Merriweather", serif;
    font-size: 16px;
    color: #777;
}

h1, h4 {
    font-family: "Montserrat", sans-serif;
}

.row {
    padding: 50px 0;
}

.seperator {
    margin-bottom: 30px;
    width: 35px;
    height: 3px;
    background: #777;
    border: none;
}

.title {
    text-align: center;
}
.title .row {
    padding: 50px 0 0;
}
.title h1 {
    text-transform: uppercase;
}
.title .seperator {
    margin: 0 auto 10px;
}

.item {
    position: relative;
    margin-bottom: 30px;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}
.item .item-in {
    background: #fff;
    padding: 40px;
    position: relative;
}
.item .item-in:hover:before {
    width: 100%;
}
.item .item-in::before {
    content: "";
    position: absolute;
    bottom: 0px;
    height: 2px;
    width: 0%;
    background: #333333;
    right: 0px;
    transition: width 0.4s;
}

.item h4 {
    font-size: 18px;
    margin-top: 25px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.item p {
    font-size: 12px;
}
.item a {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #666666;
    margin-top: 10px;
}

.item a:hover {
    text-decoration: underline;
}

.link:hover{
    font-weight: bold;
}

.arrowIcon {
    display: none;
}

.link:hover + .arrowIcon {
    display: inline;
}