.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.img_wrap {
}

.img_img {
}

.img_description_layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(9, 2, 2, 0.46);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  /* transition effect. not necessary */
  transition: opacity .7s, visibility .7s;
}

.img_wrap:hover .img_description_layer {
  visibility: visible;
  opacity: 1;
}

.img_description {
  transition: 1s;
  transform: translateY(1em);
  color: white;
}

.img_wrap:hover .img_description {
  transform: translateY(0);
}

.editableText {
  background: rgba(0, 0, 0, 0);
  border: 1px solid transparent;
  transition: border-color .2s ease-in;
  transition-delay: 1s
}

.editableText:hover {
  background: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.5);
  transition: border-color .2s ease-in;
  transition-delay: .25s
}

.category {
  height: 100%;
  position: relative;
}

.categoryImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: contrast(1.1) brightness(0.8);
  -webkit-transition: -webkit-filter 0.2s linear;
}

.categoryImage:hover {
  filter: contrast(1.0) brightness(0.7);
  -webkit-transition: -webkit-filter 0.2s linear;
}

.categoryText {
  font-size: 40px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
  text-shadow: 0 0 25px black;
}

.padding-0 {
  padding-right:0;
  padding-left:0;
}

.ig-bg {
  background: rgb(231, 191, 177);
  background: linear-gradient(0deg, rgb(231, 191, 177) 0%, rgba(249, 210, 157,1) 100%);
}

.partnerLogo {
  object-fit: fill;
  width: 100%;
  height: 150px;
  filter:  grayscale(0.75);
  -webkit-transition: -webkit-filter 0.5s linear;
}

.partnerLogo:hover {
  filter: none;
  -webkit-transition: -webkit-filter 0.5s linear;
}
